window.cookieconsent.initialise({
	palette: {
		popup: { background: "#000" },
		button: { background: "#f1d600" }
	},
	type: 'opt-in', // Use 'opt-in' if the user must explicitly consent
	content: {
		message: 'This website uses cookies to ensure you get the best experience.',
		dismiss: 'Accept',
		deny: 'Decline',
		link: 'Learn more',
		href: '/cookie-policy' // Link to your cookie policy
	},
	cookie: {
		name: 'fca_cookie'
	},
	onInitialise: function (status) {
		if (this.hasConsented()) {
			// Enable analytics or other GTM tags here
			enableGTMConsent('granted');
		} else {
			// Disable or block tags until consent is given
			enableGTMConsent('denied');
		}
	},
	onStatusChange: function (status) {
		if (this.hasConsented()) {
			// Enable GTM tags when the user consents
			enableGTMConsent('granted');
		} else {
			// Handle the case where the user denies consent
			enableGTMConsent('denied');
		}
	},
	onRevokeChoice: function () {
		// Handle the case where the user revokes consent
		enableGTMConsent('revoked');
	}
});

cookieConsent.run({
	cookie: {
		name: 'fca_cookie',
		// domain: location.hostname,
		// path: '/',
		// sameSite: "Lax",
		// expiresAfterDays: 365,
	},
})